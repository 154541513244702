(function () {
    'use strict';

    angular.module('informaApp')
        .component('summaryView', {
            templateUrl: 'components/summary-view/template.html',
            controller: SummaryView,
            bindings: {
                data: '<',
                tableGroupOptions: '<',
                hiddenDrugs: '<',
                onEmpty: '<',
                onShow: '<',
                callbacks: '<'
            }
        });

    function SummaryView($element, $q, SummaryViewService, SummaryChartTypes) {
        this.navigationPanelCallbacks = {
            barChartClick: () => this.jumpToChartByType(SummaryChartTypes.barChart),
            trendsViewClick: () => this.jumpToChartByType(SummaryChartTypes.trendsView),
            ganttChartClick: () => this.jumpToChartByType(SummaryChartTypes.ganttChart),
            boxPlotChartClick: () => this.jumpToChartByType(SummaryChartTypes.boxPlotChart),
            companyProfileClick: () => this.callbacks.companyProfileClick(this.data),
            drugLevelClick: () => this.callbacks.drugLevelClick(this.data),
        };

        this.openChart = (element) => {
            this.jumpToTopOfBody();

            switch (element.chartType) {
                case SummaryChartTypes.barChart:
                    return this.callbacks.barChartClick(this.data, element.settings);
                case SummaryChartTypes.boxPlotChart:
                    return this.callbacks.boxPlotChartClick(this.data, element.settings);
                case SummaryChartTypes.trendsView:
                    return this.callbacks.trendsViewClick(this.data, element.settings);
                case SummaryChartTypes.ganttChart:
                    return this.callbacks.ganttChartClick(this.data, element.settings);
            }
        };

        this.compareData = (first, second) => {
            const getIds = (data) => data.drugIndicationIds.shown.sort().join('') + data.drugIndicationIds.hidden.sort().join('');

            return getIds(first.data) === getIds(second.data);
        };

        this.$onInit = () => {
            if (this.data === undefined) {
                this.onEmpty();
            }
        };

        this.$onChanges = () => {
            this.profileData = this.data
                ? {
                    data: this.data,
                    tableGroupOptions: this.tableGroupOptions,
                    hiddenDrugs: this.hiddenDrugs
                } : null;
        };

        this.onTabSwitched = (tab) => {
            this.panelHiddenItems.companyProfile = !tab.data.isCompany;

            this.data = tab.data;
            this.tableGroupOptions = tab.tableGroupOptions;
            this.hiddenDrugs = tab.hiddenDrugs;
        };

        this.onProfileEmpty = () => {
            this.data = null;

            if (this.onEmpty) {
                this.onEmpty();
            }
        };

        this.onProfileShow = (tab) => {
            if (tab) {
                this.onTabSwitched(tab);
            }

            if (this.onShow) {
                this.onShow();
            }
        };

        this.onShowNumbersChanged = () => {
            if (this.views && this.views.every(view => view.rows)) {
                this.views.forEach(view => {
                    view.rows.forEach(row => {
                        row.forEach(element => {
                            element.settings = _.merge({}, element.settings, {showNumbers: this.showNumbers})
                        });
                    });
                });
            }
        };

        SummaryViewService.getAll().then((views) => {
            const onViewClick = (view) => this.activeView = view;
            this.views = mapViews(views, onViewClick);

            this.activeView = this.views[0];
        });

        this.panelHiddenItems = {
            companyProfile: true
        };

        this.jumpToChartByType = (type) => {
            moveScrollTo($element, `.element-title[data-type='${type}']`);
        };

        this.jumpToTopOfBody = () => {
            moveScrollTo($element, '.summary-view');
        }
    }

    function moveScrollTo($element, selector) {
        $('html, body').animate({
            scrollTop: $element.find(selector).offset().top
        }, 500, 'linear');
    }

    function mapViews(views, onViewClick) {
        return views.map(view => _.merge({}, view, {onClick: onViewClick}));
    }
})();
